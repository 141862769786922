// noinspection JSUnresolvedReference

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  appointments({ detail: { content } }) {
    const uniqueSet = new Set(content.appointment_type_ids);
    const uniqueRequestCourseIds = new Set(content.course_ids);
    const uniqueRequestIds = new Set(content.request_ids);
    const payload = `${content.ids}&redirect=/appointment_requests&appointment_type_id=${[...uniqueSet][0]}&course_id=${[...uniqueRequestCourseIds][0]}&appointment_request_ids=${[...uniqueRequestIds]}`

    if (uniqueSet.size > 1) {
      alert("Multiple appointment types have been selected. Bulk schedule should be for the same type of appointment.");
    } else {
        this.doFetch("/appointments/new_in_bulk", payload)
    }
  }

  appointmentCampaigns({ detail: { content } }) {
    this.doFetch("/appointment_campaigns/new_in_bulk", content.ids)
  }

  doFetch(path, payload) {
    fetch(path, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
        },
        body: `student_ids=${payload}`
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.url;
    })
    .then((responseUrl) => {
      window.location.href = responseUrl;
    })
    .catch(error => {
        console.error('Error:', error);
    });
  }
}
